<template>
  <v-card flat class="my-6 mx-9 rounded-lg" color="#f5f7f7">
    <v-row class="mx-2">
      <v-col cols="12">
        <div class="d-inline-block mr-16 grey--text">
          <div class="text-overline">Pub. date</div>
          <div class="text-overline">Ad title</div>
          <div class="text-overline">Asking price</div>
        </div>

        <div class="d-inline-block ml-16">
          <div class="text-overline">04 January 2021</div>
          <div class="text-overline font-weight-black">
            2017 Jeep Wrangler Ultimate | FRONT-END DAMAGE
          </div>
          <div class="text-overline">$ 3,850</div>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <div class="ml-2">
      <v-btn icon>
        <v-icon color="black">mdi-link</v-icon>
      </v-btn>

      <v-btn small text color="blue">View on craigslist.org</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
